
table {
    width: 98%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
   
   
}
   
th {
     font-weight: bolder;
     border: 1px solid  rgba(3, 52, 115); 
     margin-left: auto;
     margin-right: auto;
     transition: 0.3s;
}
   
th, td {
     /*padding: 1px;*/
     border: 1px;
     text-align: center;
     transition: 0.3s;
     
}

th {
     font-size: 13px;
     padding-left: 17px;
    
     
     
}

td {
     font-size: 18px;
     width: 50%;
     

}



 .mobile {
     zoom:4

}
   
td {
     border-bottom: 1px solid rgba(242, 230, 223);
     border-left: 1px solid white;
    
  
}

.itemH {
     font-size: 18px;
     font-weight: bold;
     text-align: left
    
}

.brandH, .brandId, .date {
     text-align: left;
}

   
.servingH, .weightH, .unitH, .caloriesH {
     width: 1%;
} 


.itemH, .addH, .brandH, .date, .type, .traveler, .delete {
     width: 1%;
}

/*.traveler, .type, .date {
     font-weight: bold;
}*/

.imageH {
     width: 1%;
     color: rgba(3, 52, 115);
}


.imageH:hover {
     width: 2%;
    transition: transform .9s ease;
}

.block {
     display: block;
}
   
.calPserv:hover {
     font-size: 14px;
}
     
   
.tableImage {
     
     width: 30px;
     border-radius: 5%;   
}


.noBorder {
     border: none;
     text-align: right;
     padding: 0;
     padding-top: 1px;
}


   
.quant, .gram, .cal, .brandId {
     /*font-weight: bold;*/
     transition: 0.3s;
}

   
.quant:hover, .quant:active {
     font-size: 20px; 
}
   
.calG:hover, .calG:active {
     font-size: 22px; 
     transition: 0.3s;
}

.content {
    padding-top: 200px;
}

.delete:hover {
     background-color: rgba( 191, 4, 4); 
     text-align: center;
}
 
td.delete, div.delete {
      text-align: center;
      border-left: 1px solid transparent;
      left: 0;
      right: 0

}



input.delete {
     text-align: center;
     width: 20px;
     height: 20px;
     background-color: rgba( 191, 4, 4); 
}
  
 

h2.resultsTitle, div.resultsTitle {
     display: flex;
     flex-direction: row;
     justify-content: center;
     margin-left: auto;
     margin-right: auto;
     padding-bottom: 15px;
}
         
.searchResultsTitle {
     text-align: center;
}
 

.addH {
     text-align: center;
     
}

td.add {
     border-top: 1px solid white;
     border-bottom: 1px solid white;
}

.addRow {
     width: 3%;
}

.add:hover {
     background-color: lightpink;
     /*zoom: 1.2;*/
     text-align: center;
}
   
input.add {
     text-align: center;
     width: 50px;
     height: 50px;
     background-color: rgba(49, 31, 115)
     
}



.itemDetails {
     text-align:left;
}
    
.result {
     background-color: lightpink;
     color: black
}

.bolder {
     font-weight: bolder;
}

.tableAdjust, .add {
     display: none;
}

.dataResult {
     height: 50px;

}


.visible {
     visibility: visible;
     padding: 5px;

}


.hover {
     font-size: 14px;
     color: rgba(242, 242, 242);
     height: 80px;
     width: 100%;
     padding-top: 55px;
    
     
}
 


.hover:hover {
     visibility: visible;
     color: rgba(38, 11, 9, 1);
      
     transition: 1.4s ease;
}
 



.invisible {
     display: none;

}



@media only screen and (min-width: 1px) and (max-width:666px){

.desk{
     display: none
}


.mobileHide{
     display: none;
     padding: 0;
    
}

th, td {
     /*padding: 1px;*/
     text-align: left;
     
}




}
     

 
@media only screen and (min-width: 666px) {

.tableAdjust {
     display: block;
     height: 40%
}


.mobileOnly{
     display: none
}



.mobileFixed{
     display: fixed;
     top: 0;
     z-index: 600;
}



.add {
     display: block;
}

td, th {
     font-size: 10px;

}

td {
     height: 18px;
} 


.itemH {
     font-size: 18px;

}

.servingH, .weightH, .unitH, .caloriesH{
     width: 10%;
} 



.itemH, .brandH {
     width: 14%;
}


.imageH {
     width: 8%;
}
   
}


@media only screen and (min-width: 768px) {



.mobileFixed{
     display: fixed;
     top: 0;
}
          
td, th {
font-size: 18px;

}



td {
   width: 20%;
     

}


.itemH {
     font-size: 18px;

}

.imageH {
     width: 5%;
}


.servingH, .unitH {
     width: 6%;
} 


.caloriesH, .calG, .weightH {
     width: 13%;
} 


.addH, .date, .type, .traveler, .delete {
     width: 1%;
}

.itemH {
     width: 25%;
}


.brandH {
     width: 20%;
}



.tableImage {
     width: 80px;   
}


.imageH:hover {
    zoom: 500%;
}

.resultsTitle {
     width: 50%;
    
}
       
}


@media only screen and (min-width: 1000px) {

    
td, th {
font-size: 17px;

}


.itemH {
     font-size: 17px;

}

.imageH {
     width: 5%;
}


.servingH, .unitH {
     width: 6%;
} 


.caloriesH, .calG, .weightH {
     width: 1%;
} 


th.date, tr.date  {
     width: 10%;
}

.itemH {
     width: 25%;
}


.brandH {
     width: 20%;
}



.tableImage {
     width: 80px;   
}


.visible {
     padding: 5px;

}

.imageH:hover {
     zoom: 200%;
}






.resultsTitle {
    margin-top: 30px;
    
}

div.policies {
     height: 800px;
}
          
}




@media only screen and (min-width: 1367px) {


td, th {
     font-size: 18px;

}


.itemH {
     font-size: 18px;

}

.imageH {
     width: 5%;
}


.servingH, .unitH {
     width: 6%;
} 


.caloriesH, .calG .weightH {
     width: 13%;
} 


.addH, .date, .type, .traveler, .delete {
     width: 1%;
}

.itemH {
     width: 25%;
}


.brandH {
     width: 20%;
}



.tableImage {
     width: 85px;   
}



.resultsTitle {
     margin-top: 20px;
    
}


}





@media only screen and (min-width: 2500px) {


td, th {
     font-size: 22px;

}


.itemH {
     font-size: 22px;

}

.tableImage {
     width: 120px;   
}


}