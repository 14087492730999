.tripSection {
    background-repeat: space;
    background-size: cover;
    background-position: center;
    background-image: url("https://i.ibb.co/3dHk6Dh/ostrander.jpg");
    opacity: 1;
    /*background-image: url("https://i.ibb.co/Bzx3zKz/ostrander-Original.jpg");*/
}

    
.tripContainer {
    width: auto;
    text-align: center;
    left: 0;
    right: 0;
    
}




.trips {
    text-align: left;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    border-radius: 10px;
    padding: 10px;
    width: 90%
    /*border: 1px solid rgba(191, 4, 4);*/
   

}



.trips:hover {
    opacity: 3;
    padding: 10px;
    transform: scale(1.01);
    transition: 0.3s;
    /*border: 1px solid rgba(191, 4, 4);*/  
}


.tripLi {
    /*padding: 20px;*/
    height: 120px;
    font-weight: bolder;
    opacity: 1;
    transition-timing-function: ease-in-out;
    
   
}

.tripImage:hover {
    transition-timing-function: ease-in-out;


}



.tripImage {
    /*display: inline-block;*/
    width: 40%; 
    height: 60px;
    border-radius: 10px;
    opacity: 1;
    text-align: center
}


.tripImage:hover {
    width: 45%; 
    height: 100px;
    transition: 0.9s;
      
}   
  
  
  
.trip {
    /*display: inline-block; */
    width: 40%; 
    /*padding-left: 30px;*/
    vertical-align: middle;
    height: 50px;
    padding-bottom: 30px;
    opacity: 1;
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    text-align: center;

   
    
}

 .navActions {
    float: right;
    width: 100px;
    margin-top: -120px;
    padding-top: 1px;
    text-align: center;
    font-size: 10px;
    border-radius: 5px;

 
 }


 .navDelete {
    display: inline-block;
    text-align: center;
    width: 50%;
    
      
   }


div.delete {
    font-size: 10px;
    
}


 .deleteIcon {
float: left
      
   }

li.navEdit  {
    display: inline-block;
    width: 25%;
    position: relative;
    vertical-align: middle;
    margin-top: -40px;
    margin-right: 10px;
    font-size: 10px;

 }



   


@media only screen and (min-width: 768px) {

.trip {
    font-size: 20px;
    width: 50%; 
   
}


.tripImage {
    width: 50%; 
    height: 150px;
    
    
}


.tripImage:hover {
    width: 55%;
   
}   
   

.tripLi {
    padding: 50px;
    height: 220px;

   
}


.navActions {
  
    width: 150px;
    margin-top: -180px;
    padding-top: 1px;
    
 
 }


 .navDelete {
    width: 50%;
    
      
   }


div.delete {
    font-size: 14px;
    
}


   }

li.navEdit  {
    width: 25%;
    margin-top: -90px;
    margin-right: 20px;
    font-size: 10px;

}


@media only screen and (min-width: 1366px) {

        

    

.tripLi, .trip {
    width: 50%;
    display: inline-block; 
    text-align: left;
    padding: 0px;
    padding-left: 20px;
   

}


.tripImage {
    border-radius: 30px;
   
}



.tripImage:hover {
    width: 65%; 
   
}

.trip {
    font-size: 30px;
    width: 100%; 
}




.navActions {
  
    width: 150px;
    margin-top: -200px;
    padding-top: 1px;
    margin-right: 100px
    
 
 }

 



}


@media only screen and (min-width: 2500px) {


.tripLi {
    padding: 20px
   
    
}

.trip {
    width: 50%;
    font-size: 40px;
   
}


.tripImage {
    width: 50%;
    height: 400px;
    
    
}


.tripImage:hover {
    width: 55%;
    height: 500px;
   
}


.navDelete {
    width: 80px;
  
    
 }


.navActions {
  
   margin-right: 150px
    
 
 }

        
}

