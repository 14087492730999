@import url(https://fonts.googleapis.com/css?family=Halant%7CPoppins%7CMontserrat%7CQuicksand&display=swap);
* Meyer reset */

html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

* {
    box-sizing: inherit;
}


article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* End of Meyer reset */



html {
    scroll-behavior: smooth;
    background-color:rgba(242, 242, 242);

 }
    




/* Color Theme Swatches in RGBA - represents color and font palette for this app*/
:root{

  --amplify-primary-color: rgba(83, 119, 166);
  --amplify-primary-tint:  rgba(38, 11, 9, 1); 
  --amplify-primary-shade: rgba(191, 4, 4);
  --amplify-font-family: 'Poppins', sans-serif;

  }


.plum { 
    color: rgba(166, 88, 94, 1);
  }
  
.plumBackground { 
  background-color: rgba(166, 88, 94, 1);
  fill: rgba(166, 88, 94, 1);
  

}
.mauve { 
  color: rgba(140, 108, 126, 1);
}

.mauveBackground { 
  background-color: rgba(140, 108, 126, 1);
  fill: rgba(140, 108, 126, 1);
}

.red { 
  color: rgba(191, 4, 4)
}

.redBackground { 
  background-color: rgba(191, 4, 4);
  fill: rgba(191, 4, 4)
}

.black { 
  color: rgba(38, 11, 9, 1); 
}

.dark { 
  color:black
}


.lighter {
  color: rgb(92, 59, 57)
}

.blackBackground { 
  background-color: rgba(38, 11, 9, 1);
  fill: rgba(38, 11, 9, 1); 
 
}

.salmon { 
  color: rgba(242, 76, 61, 1)
 
}

.salmonBackground { 
  background-color: rgba(242, 76, 61, 1);
  fill:rgba(242, 76, 61, 1)
}

.white {
  color: white;
}

.whiteBackground {
  background-color: white;
  fill: white
}

.skin {
  color: rgba(242, 230, 223);
}

.skinBackground {
  background-color: rgba(242, 230, 223);
  fill: rgba(242, 230, 223);
}


.blue {
  color: rgba(3, 52, 115);
}

.blueBackground {
  background-color: rgba(3, 52, 115);
  fill: rgba(3, 52, 115);
}

.purple {
  color: rgba(49, 31, 115); 
} 

.purpleBackground {
  background-color: rgba(49, 31, 115);
  fill: rgba(49, 31, 115);
} 

.green {
  color: rgba(99, 140, 69)
}

.greenBackground {
  background-color: rgba(99, 140, 69);
  fill: rgba(99, 140, 69);
}

.pinkBackground {
  background-color:rgba(242, 133, 173, 1);
  fill:rgba(242, 133, 173, 1);
}

.lightBlueBackground {
  background-color:rgba(83, 119, 166)
}

.lightBlue {
  color:rgba(83, 119, 166)
}

.cloudBlueBackground {
  background-color:rgba(173, 192, 217)

}


.cloudBlue{
  color:rgba(173, 192, 217)

}

@font-face {
  font-family: 'Montebello-Sans';
  src: url(/static/media/Montebello-Sans.9953981b.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('https://fonts.googleapis.com/css?family=Halant%7CPoppins%7CMontserrat%7CQuicksand&display=swap')
}

.montebello {
  font-family: 'Montebello-Sans',truetype;
  font-weight: lighter
}

.primaryFont {
    font-family: 'Poppins', sans-serif;
    src: url('https://fonts.googleapis.com/css?family=Halant%7CPoppins%7CMontserrat%7CQuicksand&display=swap')
}

/* End of color-font*/

/*body {
  font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1.3em + (1.5 - 1.2) * ((100vw - 300px)/(1600 - 300)));
}*/

body {
  font-size: 14px;
  color: rgba(38, 11, 9, 1)4;
    line-height: 1.5;
  font-family: 'Poppins', sans-serif;
}

h1 {
  font-size: 22px;
  text-align: center;
}

h2 {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}


h3 {
  font-size: 15px;
  /*color: rgba(242, 76, 61, 1);*/
  color: rgba(191, 4, 4);
  text-align: center;
  font-family:  'Montebello-Sans', truetype;
  font-weight: lighter;
  z-index: 200;
}


h4 {
  font-size: 14px;
  text-align: center;
  font-family: 'Montebello-Sans', truetype;
  font-weight: lighter;
  line-height: 1.7;
}

p {
  color: rgba(38, 11, 9, 1); 
  border: 1px;
  line-height: 1.8;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  z-index: 1;

}
  

.noDeco{
  text-decoration: none;
}


.hidden {
  display: none;
}

ul {
  padding: 0;
}



.bold {
  font-weight: bold;
}

span {
  border-radius: 3px;
}

::-webkit-input-placeholder {
opacity: 0.5
}

:-ms-input-placeholder {
opacity: 0.5
}

::-ms-input-placeholder {
opacity: 0.5
}

::placeholder, .opaque {
opacity: 0.5
}


@media only screen and (min-width: 1px) and (max-width:320px) {

  

  .fas {
    font-size: 10px;
    display: inline-block;
    width: 10%;
    text-align: center;

    margin-right: 5px;
   
    
  }
  
  
  }

@media only screen and (min-width: 1px) and (max-width:666px) {

  

.fas {
  font-size: 18px;

 

  
}


}

@media only screen and (min-width: 768px) {

  h2 {
    font-size: 25px;
    padding-top: 28px
    
    
  }


  .fas {
    font-size: 15px;
   
  }


  h3 {
    font-size: 15px;
    
  }
    

  ul, li, label {
    font-size: 20px;
  }





    
  }




@media only screen and (min-width: 1000px) {

  h1 {
    font-size: 40px;
    margin-top: -20px;
  }




  h3 {
    font-size: 20px;
    
  }
    

  ul, li, label {
    font-size: 25px;
  }
   

  label {
    font-size: 25px;
  }

  }




@media only screen and (min-width: 1367px) {

  

  h2 {
    font-size: 30px;
    padding-top: 30px
    
  }




  ul, li, label {
    font-size: 30px;
  }



.fas {
  font-size: 40px;

  

  
}

    
  }



@media only screen and (min-width: 2500px) {

  

  h2 {
    font-size: 35px;
    padding-top: 80px;
    
  }



  h3 {
    font-size: 25px;
    
  }
    

  ul, li, label {
    font-size: 32px;
  }
    
  }



.navContainer {
    width: 100%;
    text-align: center;
    background-color:rgba(38, 11, 9, 1);  
    transition: 0.3s;
    height: 65px;
    position: fixed;
    z-index: 3;
    overflow: hidden
}

.navbar {
   transition: 0.3s;
   z-index: 3;
   position: fixed;
}

.newAccount {
font-size: 12px;
}
.navbar:hover, .navbar:active {
    border: 1px solid rgba( 173, 192, 217);
}

.homeLogo {
    position: inherit;
    width: 50px;
    height: 50px;
    padding-left: 10px;
    padding: 5px;
    border-radius: 10px;
}
  
.tiny {
    font-size: 8px;
}
.myTrips, .signIn {
    right: 0; 
    top: 0;
    position: inherit;
    padding-top: 15px;
    font-weight: bolder;
}

.myTrips {
    padding-right: 15px;
    
}

.hello {

    font-size: 14px;
    padding-top: 20px;
    text-align: left;
    padding-bottom: 10px;
}

.signIn {
    padding-right: 100px;
    
}

.goTo {
    font-size: 16px;
   
}
    
.home {
    display: block;
    padding-top: 50px;
    text-align: center;
    padding-bottom: 5px;
    z-index: 10;
    width: 100%
}


div.policies {
    height: 1000px;
}


/*need responsive*/
.uYtitle {
    width:20%;
    margin-bottom: -20px;
    padding-top: 0;
}

.title {
    margin-bottom: -20px;
}

.description {
    padding-top: 25px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    text-align: center;
}   

.descriptionText {
    text-align: center;
    width: 90%;
    padding-top: 60px;
    position: absolute;
    font-weight: 500;
    left: 0;
    right: 0;
    z-index: 2;
    padding-left: 4px;
    padding-right: 4px;
    width: 100;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;

} 



.snowyOwl {
    border-radius: 5px;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-name: slidein;
            animation-name: slidein;
    transition-timing-function: ease-in;
    opacity: 0.6;
    width: 100%
    
   
  }


.snowyOwlContainer {
    text-align: center;
    width: 100px;
    
  }

footer {
    display: block;
    z-index: 100;
    bottom: 0px;

  
}


.logo {
width: 20px;
height: 20px;
}
  
.social {
display: flex;
justify-content: space-evenly;
padding: 0;
width: 100%;
text-align: center
}


.social_icon {
padding: 10px;


}

  
.attribution {
width: 20%;
float: right;
padding-right: 5px;
}

.policies {
height: 650px;
}



.coreValues {
    width: 100%;
    border-radius: 10px;
}

@media only screen and (min-width: 768px) {


.title {
    font-size: 30px;
}




.myTrips, .signIn {
    
    padding-top: 20px;
    font-size: 20px;
}


.contact_icon {
    display: flex;
    max-width: 100%;
    flex-grow: -1;
}

.socialcontact_icon {
    max-width: 20%; 
}


.attribution {
    width: 10%;
  }


  
.coreValues {
    width: 65%;
}


.snowyOwl {

    width: 60%   
 }



div.descriptionText {
    font-size: 14px;
    padding-top: 80px;


}
    

    
}

@media only screen and (min-width: 1000px) {



.homeLogo {
    width: 60px;
    height: 60px;
  
}


h1.title {
    font-size: 45px;
    padding-top: 20px;
}

  
  
.coreValues {
    width: 50%;
}


.snowyOwl {
   
    width: 20%   
}


}


@media only screen and (min-width: 1366px) {

.homeLogo {
    width: 100px;
    height: 100px;
    margin-left: 10px;
    
}


h1.title {
    padding-top: 60px;
}



.snowyOwl {
   
    width: 20%   
}


.navContainer {
 
    height: 120px;
   
}

div.descriptionText {
    font-size: 18px;
    padding-top: 60px;

}


.description {
    padding-top: 60px;
   
} 

.uYtitle {
    width: 15%;
    margin-bottom: -10px;
    padding-top: 0;
}


.myTrips, .signIn {
    
    padding-top: 50px;
    font-size: 30px;
}


.myTrips {

    margin-right: 95px
}



.myTrips, .signIn {
  
    margin-right: 10px
}



.attribution {
    width: 5%;
}



.goTo {
    font-size: 20px;
}

  
.coreValues {
    width: 30%;
}

}


@media only screen and (min-width: 2500px) {





.snowyOwlContainer {
    margin-top: -40px;
    
  }
.snowyOwl {
    margin-top: -40px;
    width: 10%   
    }


h1.title {
    font-size: 50px;
}



div.descriptionText {
    font-size: 30px;
  

 
   
}


.description {
    padding-top: 90px;
    margin-top: 60px;
    
   
    
} 

.uYtitle {
    width: 10%;
    margin-bottom: -10px;
    padding-top: 10;
}


.myTrips, .signIn {
    
    padding-top: 60px;
    font-size: 40px;
}


.homeLogo {
    width: 100px;
    height: 100px;
    margin-left: 10px;
    padding-top: 10px;
    
}



.goTo {
    font-size: 25px;
}


.coreValues {
    width: 55%;
}


.policies  {
    height: 1500px;
}


}
  
  
html {
    width: 100%;
}


body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

body {
    width: 100%;
    
}



* {
    box-sizing: inherit;
}


form, section {
    text-align: center;
    margin: 1%;
    border-radius: 10px;
    padding: 1px;
}

section {
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}


form {
    width: 99%;
    margin-left: auto;
    margin-right: auto;
}

form {
    background-color:  rgba(3, 52, 115);
    
}

form.quant {
    margin: 0;
    border-radius: 0;
    padding: 0;
    background-color: white}


section {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color:rgba(83, 119, 166);
    padding-top: 60px;
}   


.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
}


label {
    font-family: 'Poppins', sans-serif;
    padding-left: 5px;
    font-weight: 400; 
    
     
}

.labelWidth, .labelWidthPlan {
   width: 80%;
   display: inline-block;
   font-size: 13px;
   text-align: center;
   padding-bottom: 4px;
   padding-left: 5px;
}

.dates {
    text-align: center;
    margin-left: auto;
    margin-right: auto;

}
.labelWidthDates {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    

}

.username {
    font-size: 9px;
}

div.labelWidthDates {
   padding-bottom: 5px;
    

}
.note {
    float: left;
    margin: 10px;
    padding-left: 10px;
    font-size: 12px;
    text-align: center
    
    
    
}

.customize {
    margin-left: -35px;
    position: absolute;
 
 }

.filterHide {
    display: none
}


.filterShow {
    display: block;
    height: px;
}

input {
    display: inline-block;
    font-size: 16px;
    border-radius: 10px;
    padding: 1%;
    border: 1px solid white;
    transition: 0.1s;
    margin-left: 4px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
}
  
input[type=date] {
    width: 50%;
    margin-top: 4px;
}

 
input::-webkit-input-placeholder { 
    color: rgba(49, 31, 115)
}

 
input:-ms-input-placeholder { 
    color: rgba(49, 31, 115)
}

 
input::-ms-input-placeholder { 
    color: rgba(49, 31, 115)
}

 
input::placeholder { 
    color: rgba(49, 31, 115)
} 
  
input:hover, input:active, input[type="text"]:hover, input[type="search"]:hover, input[type="url"]:hover  {
    border: 2px solid rgba(3, 52, 115);
}
  

input[type="text"], input[type="search"], input[type="url"]{
    border: 1px solid rgba(191, 4, 4);
    height: 50px;
}


input[name="unit"], input[name="quantInput"], input[type="text"], input[type="url"]   {
    width: 80%;
}


.tooltiptext {
    font-size: 9px;
    color: grey;
   
  }
 

.tooltip {
    position: relative;
}
  
.tooltiptext {
    width: 9px;
    text-align: top;
    border-radius: 6px;
    font-size: 11px;
    font-weight: bold;
    padding-left: 2px;
    /* Position the tooltip */
    position: absolute;
    z-index: 40;
    color: transparent;
}

 
.tooltiptext:hover {
    font-size: 9px;
    color:white;
    opacity: 1;
}    

  


input[type="number"]{
    width: 100%;
    font-size: 16px;
    height: 50px;
    text-align: center;
    background-color: rgba(173, 192, 217);
    color: black;
}

.error {
    font-size: 10px;
    padding-left: 3px;
}


button[type="submit"], button[type="reset"]{
   width: 100%;
}
  
button {
    font-size: 20px;
    height: 55px;
    border-radius: 20px;
    transition: 0.1s;
    border: 1px solid transparent;
    /*border: 1px solid rgba(173, 192, 217);*/
    font-weight: lighter;
    text-align: center;
    margin: 5px;
    opacity: 0.8;
    margin-top: 10px;
    
}



button:hover {
    opacity: 1;
}
  
.button {
    max-width: 200px;
    border-radius: 5px;
    padding: 1px;
    padding-top: 5px;
    margin-bottom: 2px;
    transition: 0.1s;
    z-index: 10;
    display: inline-block;
}


.plan {
    float: left;

}


.filterButtonContainer {
   width: 50%;
   margin-left: auto;
   margin-right: auto;
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
}  

.go, .filter, .reset, .moreButton {
   display: inline-block;
   /*margin: 10px;*/
}


.buttonBox {
    width: 100%;
    text-align: center;
}

.iconButtonContainer {
    display: inline-block;
    padding: 10px;
    vertical-align: bottom;
   

}


.iconButtonsContainer {
    text-align: center;
    width: 100%;
    position: relative;

}

.iconButtons {
    width: 30%;
    padding-left: 1px;
    padding-right: 1px;
    zoom: 2;
    opacity: 0.7;
    text-align: center;
    background-color: transparent;
    border-color: transparent;
    margin-top: -5px;
    margin-bottom: -5px;

}


.iconButtons:hover {
    background-color: transparent;
    border-color: transparent;
    opacity: 1; 
}


.arrowButtons {
    padding-top: 5px;
    width: 0%;
    zoom: 1.2;
}
   
.resultsButtons {
    display: inline-block;
    text-align: center;
    width: 100%;
    padding: 1%;
}
   

.forward {
    text-align: right;
    display: inline-block;
    size: 50%;
    margin-left: 100px  
   
}



.back {
    text-align: left;
    display: inline-block;
    size: 50%
    
}

.super {
    zoom: 1.8;
    margin-left: -5px
}

.print {
float: left;
margin-top: -66px;
width: 60%
}


button[type="button"]{
   width: 10%;
   border:  transparent;
   background-color: transparent;
}

.transparent {
  background-color: transparent;
}

.back:hover, .icon:hover{
    opacity: 0.9
  
}


.greetings {
    padding: 50px;
    margin: 20px;
}

.filter, .reset {
    width: 10%;
    text-align: center;
    padding: 0;
    zoom: 3;
}


.reset, .moreButton, .filter {
    width: 120px;
}


.goContainer {
    text-align: center;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
}



.moreContainer {
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}


.moreButton {
    display: inline-block;
    margin:10px;
    padding: 10px;
    border-radius: 10px;
    opacity: 0.8
}


.moreButton:hover {
    font-weight: bolder;
    opacity: 1
}


.go {
   margin-right: 0;
   margin-left: 0;
   display: inline-block
}



.scroll {
    height: 85px;
    overflow-x: hidden;
    overflow-y: auto;
    /*margin-right: 40px;*/
}


.filterSection {
    text-align: center;
    display: block;
    width: 100%
 }




.filterContainer {
    height: auto;
    background-color: white;
    border-radius: 3%;
    padding: 10px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
}
  
.filterCategory {
    text-align: center;
    display: inline-block;
    width: 80%;
}

.summary {
    zoom: 0.7
}


.boost {
    zoom: 0.9
}



.summary:hover {
    display:inherit;
}


.labelContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: left;
}

/* Hide the browser's default radio button */
.labelContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
    z-index: 1;
}



.checked {
    font:red;
   
}

.labelContainer:hover input ~ .checkmark {
    background-color:  rgba(191, 4, 4);
    z-index: 1;
  }



.charts {
    padding: 10px;
    margin: 10px;
    float: right;
    font-weight: bolder;
    border-radius: 2px;

}



text {
    text-align: center;
    font-size: 8px;
  
}

.bar {
    height: 21px;
    transition: fill .3s ease;
    cursor: pointer;
    font-family: Helvetica, sans-serif;
}

.bar text {
    color: black;
}

rect:hover,
rect:focus,
.bar:hover,
.bar:focus {
    fill: rgba(49, 31, 115);
}

.bar:hover text,
.bar:focus text {
    fill: white; 
}

svg {
    float: right;
    padding-right: 10px;
}

.graphText {
    padding-right: 10px;
    font-size: 14px;
}

.inputBox {
    left: 0;
    right: 0;
    width: 25%;
    display: inline-block;
    padding-bottom: 5px;
    padding-top: 5px;
    text-align: center;

    
}

.resultBox {
    width: 40%;
    
   
}

.cellBox {

    text-align: center;
    padding-left: 50%;
    
}

.resultsContainer {
    display: flex;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.total {
    display: table-cell;
    vertical-align: middle;
    height: 30px;
    text-align: center
  

}

.dataInput, .textInput {
    height: 50px;
}


.dataInput, .textInput {
    width: 60%;
    display: inline-block;
    /*padding-left: 20px;*/
    /*margin-left: 20px;*/
    border-radius: 10%;
    vertical-align: middle;
    z-index: 1;
    
}


.textInput {
    width: 100%;
    display: inline-block;
    /*padding-left: 20px;*/
    /*margin-left: 20px;*/
}


.calcBox {
    width: 100%;
   text-align: center;

}

.inputsContainer, .resultsContainer {
    width: 100%;
    text-align: center;

}


.adjustImage {
   float: right;
    top: 0;
    margin-right: 3px;
    margin-top: 12px;
    zoom: 4;
    
    
}

.myPlans {
    padding-top: 50px;
}


@media only screen and (min-width: 1px) and (max-width:666px) {

.back, .forward {
    top: -10px;

}

button[type="button"]{
    font: 14px;
}


.forward {
    size: 20%;
    margin-left: 150px

   
}


.back {   
    size: 10%
    
}


div.newI, div.inputBox {
    width: 100%;
   
}

.labelWidth, .labelWidthPlan, .labelWidthDates {
    width: 90%
}

input.dataResult {
    width: 75%
}

input.servingUnit {
    width: 50%
}



div.newI, div.calcBox {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 100%

    
}






@media only screen and (min-width: 666px) {

button {
    height: 60px;

}

.reset, .moreButton, .filter {
    font-size: 14px;
    width: 35%

}
text {
    font-size: 28px
}

button[type="button"]{
    height: 4px;
    font: 14px;
}



.labelWidth, .labeWidthPlan {
    font-size: 15px;
    
}


.labelWidth, .labelWidthPlan {
   width: 80%
}


}




}
@media only screen and (min-width: 768px) {



button[type="submit"], button[type="reset"]{
        width: 40%;
 }

.newI {

    display: flex;
    justify-content: space-evenly
}

.newItems {
    width: 40%;
    display: inline-block;
    text-align: center;
}



.forward {
    
    margin-left: 200px  
   
}


.labelContainer {
    font-size: 17px;
}



button {
    font-size: 25px;
    height: 50px;
    
}


.iconButtons {

    zoom: 1.5;
 
}


.adjustImage {

    margin-right: 40px;
    margin-top: 55px;
    zoom: 1.2;

    
}

.tooltip .tooltiptext {

    font-size: 12px;

}


.reset, .moreButton, .filter {
    font-size: 20px;
    width: 40%

}


.resultBox {
    width: 30%;
    
   
}

.greetings {
    padding: 10px;
}


.myPlans {
    padding-top: 10px;
}


.excel {
    position: absolute;
    top: 134px;
    left: 10px}
    
    .print {
    float: left;
    margin-top: -66px;
    }
    

}

@media only screen and (min-width: 1000px) {


button {
    font-size: 30px;
    height: 50px;
    
}



button[type="submit"], button[type="reset"]{
    width: 30%;
}


.labelContainer {
    font-size: 16px;
}



.adjustImage {
    margin-right: 40px;
    margin-top: 20px;
    zoom: 1.5;
    padding-top: 10px;
    
    
}
.labelWidthPlan, .labelWidth{
    font-size: 20px;
}

.customize {
    margin-left: -50px;
}

input[type="number"], input[type="text"], input[type="date"], input[type="url"],  input[type="submit"], .dataResult{
    font-size: 20px;
    
}


input[type="text"], input[type="date"], input[type="url"] {
    width: 250px
        

}

input[type="date"] {
    width: 150px
}



.resultsContainer {
    
    padding-top: 10px;
    padding-bottom: 10px;
}



.iconButtons {
    zoom: 1.5;
    margin-top: -40px;
    margin-bottom: -5px;
    
   }

.moreButton {
    font-size: 20px;
}
 

.forward {
   
    margin-left: 400px  
   
}


.resultBox {
    width: 25%;
   
}

div.labelWidthDates {
    width: 20%;
    display: inline-block;
    text-align: center;


}


.greetings {
    font-size: 20px;
}

.note {
    float: left;
    margin: 10px;
    padding-left: 10px;
    font-size: 12px;
    text-align: left;
    width: 100px;
    
    
    
}



.excel {
    position: absolute;
    top: 205px;
    left: 10px}
    

}


@media only screen and (min-width: 1366px) {


.excel {
    
    left: 200px}
    

.print {
    
    padding-top: 50px;
    }

.myPlans {
    padding-top: 10px;
}

input.dataResult {
    width: 35%
}


input[type= "text"].servingUnit {
    width: 50%
}


div.labelWidthPlan {
    width: 25%;
    display: inline-block;
    text-align: center;

}

.newI {

    display: flex;
    flex-wrap: wrap;
}

.newItems {
    width: 35%;
    display: inline-block;
    text-align: center;
}




div.labelWidthDates {
    width: 20%;
    display: inline-block;
    text-align: center;

}


button {
    font-size: 33px;
    height: 60px;
    
}


.labelWidthPlan, .labelWidth, .dataResult{
    font-size: 22px;
}



.labelWidth {
    padding-top: 30px;
}


.adjustImage {
    margin-top: 50px;
    zoom: 1.4;
    
    
}

input[type="number"], input[type="text"], input[type="date"], input[type="url"], input[type="submit"].dataResult{
    font-size: 21px;
    
}





.dataResult {
    margin: 10px;;
   
}

input[type="text"], input[type="date"], input[type="url"] {
    width: 300px
        

}


 
input[type="date"] {
    width: 200px
        

}

form {
    padding-bottom: 20px;
}




.arrowButtons {
    padding-top: 53px;
    zoom: 1.3
    
}



.forward {
   
    margin-left: 500px  
   
}




.charts {
    padding-top: 60px;
    border-radius: 5px;
    
    
}



.graphText {
    padding-right: 10px;
    font-size: 20px;
}


.labelContainer {
  
    width: 400%;
    display: inline-block
}

.filterContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    


}


.note {
    padding-left: 10px;
    font-size: 15px;
    width: 200px;
}

    
}
        
        
@media only screen and (min-width: 2500px) {
   
.labelWidthPlan, .labelWidth, .labelWidthDates{
        font-size: 35px;
    }
    
    

ul, li, label {
    font-size: 35px;
}
          

.customize {
    margin-left: -50px;
}

input[type="number"], input[type="text"], input[type="date"], input[type="url"], .dataResult{
    font-size: 30px;
    
}


 
input[type="text"], input[type="date"], input[type="url"] {
    width: 450px
        

}


 
input[type="date"] {
    width: 350px
        

}


.dataResult {
    width: 30%
}

input[type="number"] {
    width: 30%;
    
}

input[type="submit"] {
    width: 40%;
    
}



.iconButtons {
    width: 6%;
    zoom: 2;
    
    
}

.moreButton {
    font-size: 35px;
}



.arrowButtons {
    zoom: 1.4;
}


.forward {
   
    margin-left: 900px  
   
}



.dd  {
    padding-right: 10px;
    font-size: 35px;
}


.adjustImage {
    margin-top: 15px;
    margin-right: 30px;
    
    
}


.tooltip .tooltiptext {

    font-size: 12px;

}

label.labelContainer {
  
    font-size: 30px;
}


.scroll {
    height: 300px;
   
}


button {
    font-size: 45px;
    height: 85px;
    
}

.resultBox {
    width: 20%;
   
}




.note {
    padding-left: 10px;
    font-size: 25px;
    width: 300px;
}

.excel {
  
    top: 340px;
    left: 170px;}


.print {
      
    top: 100px;
    left: 0}
 

     
    


}




table {
    width: 98%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
   
   
}
   
th {
     font-weight: bolder;
     border: 1px solid  rgba(3, 52, 115); 
     margin-left: auto;
     margin-right: auto;
     transition: 0.3s;
}
   
th, td {
     /*padding: 1px;*/
     border: 1px;
     text-align: center;
     transition: 0.3s;
     
}

th {
     font-size: 13px;
     padding-left: 17px;
    
     
     
}

td {
     font-size: 18px;
     width: 50%;
     

}



 .mobile {
     zoom:4

}
   
td {
     border-bottom: 1px solid rgba(242, 230, 223);
     border-left: 1px solid white;
    
  
}

.itemH {
     font-size: 18px;
     font-weight: bold;
     text-align: left
    
}

.brandH, .brandId, .date {
     text-align: left;
}

   
.servingH, .weightH, .unitH, .caloriesH {
     width: 1%;
} 


.itemH, .addH, .brandH, .date, .type, .traveler, .delete {
     width: 1%;
}

/*.traveler, .type, .date {
     font-weight: bold;
}*/

.imageH {
     width: 1%;
     color: rgba(3, 52, 115);
}


.imageH:hover {
     width: 2%;
    transition: transform .9s ease;
}

.block {
     display: block;
}
   
.calPserv:hover {
     font-size: 14px;
}
     
   
.tableImage {
     
     width: 30px;
     border-radius: 5%;   
}


.noBorder {
     border: none;
     text-align: right;
     padding: 0;
     padding-top: 1px;
}


   
.quant, .gram, .cal, .brandId {
     /*font-weight: bold;*/
     transition: 0.3s;
}

   
.quant:hover, .quant:active {
     font-size: 20px; 
}
   
.calG:hover, .calG:active {
     font-size: 22px; 
     transition: 0.3s;
}

.content {
    padding-top: 200px;
}

.delete:hover {
     background-color: rgba( 191, 4, 4); 
     text-align: center;
}
 
td.delete, div.delete {
      text-align: center;
      border-left: 1px solid transparent;
      left: 0;
      right: 0

}



input.delete {
     text-align: center;
     width: 20px;
     height: 20px;
     background-color: rgba( 191, 4, 4); 
}
  
 

h2.resultsTitle, div.resultsTitle {
     display: flex;
     flex-direction: row;
     justify-content: center;
     margin-left: auto;
     margin-right: auto;
     padding-bottom: 15px;
}
         
.searchResultsTitle {
     text-align: center;
}
 

.addH {
     text-align: center;
     
}

td.add {
     border-top: 1px solid white;
     border-bottom: 1px solid white;
}

.addRow {
     width: 3%;
}

.add:hover {
     background-color: lightpink;
     /*zoom: 1.2;*/
     text-align: center;
}
   
input.add {
     text-align: center;
     width: 50px;
     height: 50px;
     background-color: rgba(49, 31, 115)
     
}



.itemDetails {
     text-align:left;
}
    
.result {
     background-color: lightpink;
     color: black
}

.bolder {
     font-weight: bolder;
}

.tableAdjust, .add {
     display: none;
}

.dataResult {
     height: 50px;

}


.visible {
     visibility: visible;
     padding: 5px;

}


.hover {
     font-size: 14px;
     color: rgba(242, 242, 242);
     height: 80px;
     width: 100%;
     padding-top: 55px;
    
     
}
 


.hover:hover {
     visibility: visible;
     color: rgba(38, 11, 9, 1);
      
     transition: 1.4s ease;
}
 



.invisible {
     display: none;

}



@media only screen and (min-width: 1px) and (max-width:666px){

.desk{
     display: none
}


.mobileHide{
     display: none;
     padding: 0;
    
}

th, td {
     /*padding: 1px;*/
     text-align: left;
     
}




}
     

 
@media only screen and (min-width: 666px) {

.tableAdjust {
     display: block;
     height: 40%
}


.mobileOnly{
     display: none
}



.mobileFixed{
     display: fixed;
     top: 0;
     z-index: 600;
}



.add {
     display: block;
}

td, th {
     font-size: 10px;

}

td {
     height: 18px;
} 


.itemH {
     font-size: 18px;

}

.servingH, .weightH, .unitH, .caloriesH{
     width: 10%;
} 



.itemH, .brandH {
     width: 14%;
}


.imageH {
     width: 8%;
}
   
}


@media only screen and (min-width: 768px) {



.mobileFixed{
     display: fixed;
     top: 0;
}
          
td, th {
font-size: 18px;

}



td {
   width: 20%;
     

}


.itemH {
     font-size: 18px;

}

.imageH {
     width: 5%;
}


.servingH, .unitH {
     width: 6%;
} 


.caloriesH, .calG, .weightH {
     width: 13%;
} 


.addH, .date, .type, .traveler, .delete {
     width: 1%;
}

.itemH {
     width: 25%;
}


.brandH {
     width: 20%;
}



.tableImage {
     width: 80px;   
}


.imageH:hover {
    zoom: 500%;
}

.resultsTitle {
     width: 50%;
    
}
       
}


@media only screen and (min-width: 1000px) {

    
td, th {
font-size: 17px;

}


.itemH {
     font-size: 17px;

}

.imageH {
     width: 5%;
}


.servingH, .unitH {
     width: 6%;
} 


.caloriesH, .calG, .weightH {
     width: 1%;
} 


th.date, tr.date  {
     width: 10%;
}

.itemH {
     width: 25%;
}


.brandH {
     width: 20%;
}



.tableImage {
     width: 80px;   
}


.visible {
     padding: 5px;

}

.imageH:hover {
     zoom: 200%;
}






.resultsTitle {
    margin-top: 30px;
    
}

div.policies {
     height: 800px;
}
          
}




@media only screen and (min-width: 1367px) {


td, th {
     font-size: 18px;

}


.itemH {
     font-size: 18px;

}

.imageH {
     width: 5%;
}


.servingH, .unitH {
     width: 6%;
} 


.caloriesH, .calG .weightH {
     width: 13%;
} 


.addH, .date, .type, .traveler, .delete {
     width: 1%;
}

.itemH {
     width: 25%;
}


.brandH {
     width: 20%;
}



.tableImage {
     width: 85px;   
}



.resultsTitle {
     margin-top: 20px;
    
}


}





@media only screen and (min-width: 2500px) {


td, th {
     font-size: 22px;

}


.itemH {
     font-size: 22px;

}

.tableImage {
     width: 120px;   
}


}
.tripSection {
    background-repeat: space;
    background-size: cover;
    background-position: center;
    background-image: url("https://i.ibb.co/3dHk6Dh/ostrander.jpg");
    opacity: 1;
    /*background-image: url("https://i.ibb.co/Bzx3zKz/ostrander-Original.jpg");*/
}

    
.tripContainer {
    width: auto;
    text-align: center;
    left: 0;
    right: 0;
    
}




.trips {
    text-align: left;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    border-radius: 10px;
    padding: 10px;
    width: 90%
    /*border: 1px solid rgba(191, 4, 4);*/
   

}



.trips:hover {
    opacity: 3;
    padding: 10px;
    transform: scale(1.01);
    transition: 0.3s;
    /*border: 1px solid rgba(191, 4, 4);*/  
}


.tripLi {
    /*padding: 20px;*/
    height: 120px;
    font-weight: bolder;
    opacity: 1;
    transition-timing-function: ease-in-out;
    
   
}

.tripImage:hover {
    transition-timing-function: ease-in-out;


}



.tripImage {
    /*display: inline-block;*/
    width: 40%; 
    height: 60px;
    border-radius: 10px;
    opacity: 1;
    text-align: center
}


.tripImage:hover {
    width: 45%; 
    height: 100px;
    transition: 0.9s;
      
}   
  
  
  
.trip {
    /*display: inline-block; */
    width: 40%; 
    /*padding-left: 30px;*/
    vertical-align: middle;
    height: 50px;
    padding-bottom: 30px;
    opacity: 1;
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    text-align: center;

   
    
}

 .navActions {
    float: right;
    width: 100px;
    margin-top: -120px;
    padding-top: 1px;
    text-align: center;
    font-size: 10px;
    border-radius: 5px;

 
 }


 .navDelete {
    display: inline-block;
    text-align: center;
    width: 50%;
    
      
   }


div.delete {
    font-size: 10px;
    
}


 .deleteIcon {
float: left
      
   }

li.navEdit  {
    display: inline-block;
    width: 25%;
    position: relative;
    vertical-align: middle;
    margin-top: -40px;
    margin-right: 10px;
    font-size: 10px;

 }



   


@media only screen and (min-width: 768px) {

.trip {
    font-size: 20px;
    width: 50%; 
   
}


.tripImage {
    width: 50%; 
    height: 150px;
    
    
}


.tripImage:hover {
    width: 55%;
   
}   
   

.tripLi {
    padding: 50px;
    height: 220px;

   
}


.navActions {
  
    width: 150px;
    margin-top: -180px;
    padding-top: 1px;
    
 
 }


 .navDelete {
    width: 50%;
    
      
   }


div.delete {
    font-size: 14px;
    
}


   }

li.navEdit  {
    width: 25%;
    margin-top: -90px;
    margin-right: 20px;
    font-size: 10px;

}


@media only screen and (min-width: 1366px) {

        

    

.tripLi, .trip {
    width: 50%;
    display: inline-block; 
    text-align: left;
    padding: 0px;
    padding-left: 20px;
   

}


.tripImage {
    border-radius: 30px;
   
}



.tripImage:hover {
    width: 65%; 
   
}

.trip {
    font-size: 30px;
    width: 100%; 
}




.navActions {
  
    width: 150px;
    margin-top: -200px;
    padding-top: 1px;
    margin-right: 100px
    
 
 }

 



}


@media only screen and (min-width: 2500px) {


.tripLi {
    padding: 20px
   
    
}

.trip {
    width: 50%;
    font-size: 40px;
   
}


.tripImage {
    width: 50%;
    height: 400px;
    
    
}


.tripImage:hover {
    width: 55%;
    height: 500px;
   
}


.navDelete {
    width: 80px;
  
    
 }


.navActions {
  
   margin-right: 150px
    
 
 }

        
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


