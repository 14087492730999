


.navContainer {
    width: 100%;
    text-align: center;
    background-color:rgba(38, 11, 9, 1);  
    transition: 0.3s;
    height: 65px;
    position: fixed;
    z-index: 3;
    overflow: hidden
}

.navbar {
   transition: 0.3s;
   z-index: 3;
   position: fixed;
}

.newAccount {
font-size: 12px;
}
.navbar:hover, .navbar:active {
    border: 1px solid rgba( 173, 192, 217);
}

.homeLogo {
    position: inherit;
    width: 50px;
    height: 50px;
    padding-left: 10px;
    padding: 5px;
    border-radius: 10px;
}
  
.tiny {
    font-size: 8px;
}
.myTrips, .signIn {
    right: 0; 
    top: 0;
    position: inherit;
    padding-top: 15px;
    font-weight: bolder;
}

.myTrips {
    padding-right: 15px;
    
}

.hello {

    font-size: 14px;
    padding-top: 20px;
    text-align: left;
    padding-bottom: 10px;
}

.signIn {
    padding-right: 100px;
    
}

.goTo {
    font-size: 16px;
   
}
    
.home {
    display: block;
    padding-top: 50px;
    text-align: center;
    padding-bottom: 5px;
    z-index: 10;
    width: 100%
}


div.policies {
    height: 1000px;
}


/*need responsive*/
.uYtitle {
    width:20%;
    margin-bottom: -20px;
    padding-top: 0;
}

.title {
    margin-bottom: -20px;
}

.description {
    padding-top: 25px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    text-align: center;
}   

.descriptionText {
    text-align: center;
    width: 90%;
    padding-top: 60px;
    position: absolute;
    font-weight: 500;
    left: 0;
    right: 0;
    z-index: 2;
    padding-left: 4px;
    padding-right: 4px;
    width: 100;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;

} 



.snowyOwl {
    border-radius: 5px;
    animation-duration: 4s;
    animation-name: slidein;
    transition-timing-function: ease-in;
    opacity: 0.6;
    width: 100%
    
   
  }


.snowyOwlContainer {
    text-align: center;
    width: 100px;
    
  }

footer {
    display: block;
    z-index: 100;
    bottom: 0px;

  
}


.logo {
width: 20px;
height: 20px;
}
  
.social {
display: flex;
justify-content: space-evenly;
padding: 0;
width: 100%;
text-align: center
}


.social_icon {
padding: 10px;


}

  
.attribution {
width: 20%;
float: right;
padding-right: 5px;
}

.policies {
height: 650px;
}



.coreValues {
    width: 100%;
    border-radius: 10px;
}

@media only screen and (min-width: 768px) {


.title {
    font-size: 30px;
}




.myTrips, .signIn {
    
    padding-top: 20px;
    font-size: 20px;
}


.contact_icon {
    display: flex;
    max-width: 100%;
    flex-grow: -1;
}

.socialcontact_icon {
    max-width: 20%; 
}


.attribution {
    width: 10%;
  }


  
.coreValues {
    width: 65%;
}


.snowyOwl {

    width: 60%   
 }



div.descriptionText {
    font-size: 14px;
    padding-top: 80px;


}
    

    
}

@media only screen and (min-width: 1000px) {



.homeLogo {
    width: 60px;
    height: 60px;
  
}


h1.title {
    font-size: 45px;
    padding-top: 20px;
}

  
  
.coreValues {
    width: 50%;
}


.snowyOwl {
   
    width: 20%   
}


}


@media only screen and (min-width: 1366px) {

.homeLogo {
    width: 100px;
    height: 100px;
    margin-left: 10px;
    
}


h1.title {
    padding-top: 60px;
}



.snowyOwl {
   
    width: 20%   
}


.navContainer {
 
    height: 120px;
   
}

div.descriptionText {
    font-size: 18px;
    padding-top: 60px;

}


.description {
    padding-top: 60px;
   
} 

.uYtitle {
    width: 15%;
    margin-bottom: -10px;
    padding-top: 0;
}


.myTrips, .signIn {
    
    padding-top: 50px;
    font-size: 30px;
}


.myTrips {

    margin-right: 95px
}



.myTrips, .signIn {
  
    margin-right: 10px
}



.attribution {
    width: 5%;
}



.goTo {
    font-size: 20px;
}

  
.coreValues {
    width: 30%;
}

}


@media only screen and (min-width: 2500px) {





.snowyOwlContainer {
    margin-top: -40px;
    
  }
.snowyOwl {
    margin-top: -40px;
    width: 10%   
    }


h1.title {
    font-size: 50px;
}



div.descriptionText {
    font-size: 30px;
  

 
   
}


.description {
    padding-top: 90px;
    margin-top: 60px;
    
   
    
} 

.uYtitle {
    width: 10%;
    margin-bottom: -10px;
    padding-top: 10;
}


.myTrips, .signIn {
    
    padding-top: 60px;
    font-size: 40px;
}


.homeLogo {
    width: 100px;
    height: 100px;
    margin-left: 10px;
    padding-top: 10px;
    
}



.goTo {
    font-size: 25px;
}


.coreValues {
    width: 55%;
}


.policies  {
    height: 1500px;
}


}
  