  
html {
    width: 100%;
}


body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

body {
    width: 100%;
    
}



* {
    box-sizing: inherit;
}


form, section {
    text-align: center;
    margin: 1%;
    border-radius: 10px;
    padding: 1px;
}

section {
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}


form {
    width: 99%;
    margin-left: auto;
    margin-right: auto;
}

form {
    background-color:  rgba(3, 52, 115);
    
}

form.quant {
    margin: 0;
    border-radius: 0;
    padding: 0;
    background-color: white}


section {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color:rgba(83, 119, 166);
    padding-top: 60px;
}   


.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
}


label {
    font-family: 'Poppins', sans-serif;
    padding-left: 5px;
    font-weight: 400; 
    
     
}

.labelWidth, .labelWidthPlan {
   width: 80%;
   display: inline-block;
   font-size: 13px;
   text-align: center;
   padding-bottom: 4px;
   padding-left: 5px;
}

.dates {
    text-align: center;
    margin-left: auto;
    margin-right: auto;

}
.labelWidthDates {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    

}

.username {
    font-size: 9px;
}

div.labelWidthDates {
   padding-bottom: 5px;
    

}
.note {
    float: left;
    margin: 10px;
    padding-left: 10px;
    font-size: 12px;
    text-align: center
    
    
    
}

.customize {
    margin-left: -35px;
    position: absolute;
 
 }

.filterHide {
    display: none
}


.filterShow {
    display: block;
    height: px;
}

input {
    display: inline-block;
    font-size: 16px;
    border-radius: 10px;
    padding: 1%;
    border: 1px solid white;
    transition: 0.1s;
    margin-left: 4px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
}
  
input[type=date] {
    width: 50%;
    margin-top: 4px;
}

 
input::placeholder { 
    color: rgba(49, 31, 115)
} 
  
input:hover, input:active, input[type="text"]:hover, input[type="search"]:hover, input[type="url"]:hover  {
    border: 2px solid rgba(3, 52, 115);
}
  

input[type="text"], input[type="search"], input[type="url"]{
    border: 1px solid rgba(191, 4, 4);
    height: 50px;
}


input[name="unit"], input[name="quantInput"], input[type="text"], input[type="url"]   {
    width: 80%;
}


.tooltiptext {
    font-size: 9px;
    color: grey;
   
  }
 

.tooltip {
    position: relative;
}
  
.tooltiptext {
    width: 9px;
    text-align: top;
    border-radius: 6px;
    font-size: 11px;
    font-weight: bold;
    padding-left: 2px;
    /* Position the tooltip */
    position: absolute;
    z-index: 40;
    color: transparent;
}

 
.tooltiptext:hover {
    font-size: 9px;
    color:white;
    opacity: 1;
}    

  


input[type="number"]{
    width: 100%;
    font-size: 16px;
    height: 50px;
    text-align: center;
    background-color: rgba(173, 192, 217);
    color: black;
}

.error {
    font-size: 10px;
    padding-left: 3px;
}


button[type="submit"], button[type="reset"]{
   width: 100%;
}
  
button {
    font-size: 20px;
    height: 55px;
    border-radius: 20px;
    transition: 0.1s;
    border: 1px solid transparent;
    /*border: 1px solid rgba(173, 192, 217);*/
    font-weight: lighter;
    text-align: center;
    margin: 5px;
    opacity: 0.8;
    margin-top: 10px;
    
}



button:hover {
    opacity: 1;
}
  
.button {
    max-width: 200px;
    border-radius: 5px;
    padding: 1px;
    padding-top: 5px;
    margin-bottom: 2px;
    transition: 0.1s;
    z-index: 10;
    display: inline-block;
}


.plan {
    float: left;

}


.filterButtonContainer {
   width: 50%;
   margin-left: auto;
   margin-right: auto;
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
}  

.go, .filter, .reset, .moreButton {
   display: inline-block;
   /*margin: 10px;*/
}


.buttonBox {
    width: 100%;
    text-align: center;
}

.iconButtonContainer {
    display: inline-block;
    padding: 10px;
    vertical-align: bottom;
   

}


.iconButtonsContainer {
    text-align: center;
    width: 100%;
    position: relative;

}

.iconButtons {
    width: 30%;
    padding-left: 1px;
    padding-right: 1px;
    zoom: 2;
    opacity: 0.7;
    text-align: center;
    background-color: transparent;
    border-color: transparent;
    margin-top: -5px;
    margin-bottom: -5px;

}


.iconButtons:hover {
    background-color: transparent;
    border-color: transparent;
    opacity: 1; 
}


.arrowButtons {
    padding-top: 5px;
    width: 0%;
    zoom: 1.2;
}
   
.resultsButtons {
    display: inline-block;
    text-align: center;
    width: 100%;
    padding: 1%;
}
   

.forward {
    text-align: right;
    display: inline-block;
    size: 50%;
    margin-left: 100px  
   
}



.back {
    text-align: left;
    display: inline-block;
    size: 50%
    
}

.super {
    zoom: 1.8;
    margin-left: -5px
}

.print {
float: left;
margin-top: -66px;
width: 60%
}


button[type="button"]{
   width: 10%;
   border:  transparent;
   background-color: transparent;
}

.transparent {
  background-color: transparent;
}

.back:hover, .icon:hover{
    opacity: 0.9
  
}


.greetings {
    padding: 50px;
    margin: 20px;
}

.filter, .reset {
    width: 10%;
    text-align: center;
    padding: 0;
    zoom: 3;
}


.reset, .moreButton, .filter {
    width: 120px;
}


.goContainer {
    text-align: center;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
}



.moreContainer {
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}


.moreButton {
    display: inline-block;
    margin:10px;
    padding: 10px;
    border-radius: 10px;
    opacity: 0.8
}


.moreButton:hover {
    font-weight: bolder;
    opacity: 1
}


.go {
   margin-right: 0;
   margin-left: 0;
   display: inline-block
}



.scroll {
    height: 85px;
    overflow-x: hidden;
    overflow-y: auto;
    /*margin-right: 40px;*/
}


.filterSection {
    text-align: center;
    display: block;
    width: 100%
 }




.filterContainer {
    height: auto;
    background-color: white;
    border-radius: 3%;
    padding: 10px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
}
  
.filterCategory {
    text-align: center;
    display: inline-block;
    width: 80%;
}

.summary {
    zoom: 0.7
}


.boost {
    zoom: 0.9
}



.summary:hover {
    display:inherit;
}


.labelContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: left;
}

/* Hide the browser's default radio button */
.labelContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
    z-index: 1;
}



.checked {
    font:red;
   
}

.labelContainer:hover input ~ .checkmark {
    background-color:  rgba(191, 4, 4);
    z-index: 1;
  }



.charts {
    padding: 10px;
    margin: 10px;
    float: right;
    font-weight: bolder;
    border-radius: 2px;

}



text {
    text-align: center;
    font-size: 8px;
  
}

.bar {
    height: 21px;
    transition: fill .3s ease;
    cursor: pointer;
    font-family: Helvetica, sans-serif;
}

.bar text {
    color: black;
}

rect:hover,
rect:focus,
.bar:hover,
.bar:focus {
    fill: rgba(49, 31, 115);
}

.bar:hover text,
.bar:focus text {
    fill: white; 
}

svg {
    float: right;
    padding-right: 10px;
}

.graphText {
    padding-right: 10px;
    font-size: 14px;
}

.inputBox {
    left: 0;
    right: 0;
    width: 25%;
    display: inline-block;
    padding-bottom: 5px;
    padding-top: 5px;
    text-align: center;

    
}

.resultBox {
    width: 40%;
    
   
}

.cellBox {

    text-align: center;
    padding-left: 50%;
    
}

.resultsContainer {
    display: flex;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.total {
    display: table-cell;
    vertical-align: middle;
    height: 30px;
    text-align: center
  

}

.dataInput, .textInput {
    height: 50px;
}


.dataInput, .textInput {
    width: 60%;
    display: inline-block;
    /*padding-left: 20px;*/
    /*margin-left: 20px;*/
    border-radius: 10%;
    vertical-align: middle;
    z-index: 1;
    
}


.textInput {
    width: 100%;
    display: inline-block;
    /*padding-left: 20px;*/
    /*margin-left: 20px;*/
}


.calcBox {
    width: 100%;
   text-align: center;

}

.inputsContainer, .resultsContainer {
    width: 100%;
    text-align: center;

}


.adjustImage {
   float: right;
    top: 0;
    margin-right: 3px;
    margin-top: 12px;
    zoom: 4;
    
    
}

.myPlans {
    padding-top: 50px;
}


@media only screen and (min-width: 1px) and (max-width:666px) {

.back, .forward {
    top: -10px;

}

button[type="button"]{
    font: 14px;
}


.forward {
    size: 20%;
    margin-left: 150px

   
}


.back {   
    size: 10%
    
}


div.newI, div.inputBox {
    width: 100%;
   
}

.labelWidth, .labelWidthPlan, .labelWidthDates {
    width: 90%
}

input.dataResult {
    width: 75%
}

input.servingUnit {
    width: 50%
}



div.newI, div.calcBox {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 100%

    
}






@media only screen and (min-width: 666px) {

button {
    height: 60px;

}

.reset, .moreButton, .filter {
    font-size: 14px;
    width: 35%

}
text {
    font-size: 28px
}

button[type="button"]{
    height: 4px;
    font: 14px;
}



.labelWidth, .labeWidthPlan {
    font-size: 15px;
    
}


.labelWidth, .labelWidthPlan {
   width: 80%
}


}




}
@media only screen and (min-width: 768px) {



button[type="submit"], button[type="reset"]{
        width: 40%;
 }

.newI {

    display: flex;
    justify-content: space-evenly
}

.newItems {
    width: 40%;
    display: inline-block;
    text-align: center;
}



.forward {
    
    margin-left: 200px  
   
}


.labelContainer {
    font-size: 17px;
}



button {
    font-size: 25px;
    height: 50px;
    
}


.iconButtons {

    zoom: 1.5;
 
}


.adjustImage {

    margin-right: 40px;
    margin-top: 55px;
    zoom: 1.2;

    
}

.tooltip .tooltiptext {

    font-size: 12px;

}


.reset, .moreButton, .filter {
    font-size: 20px;
    width: 40%

}


.resultBox {
    width: 30%;
    
   
}

.greetings {
    padding: 10px;
}


.myPlans {
    padding-top: 10px;
}


.excel {
    position: absolute;
    top: 134px;
    left: 10px}
    
    .print {
    float: left;
    margin-top: -66px;
    }
    

}

@media only screen and (min-width: 1000px) {


button {
    font-size: 30px;
    height: 50px;
    
}



button[type="submit"], button[type="reset"]{
    width: 30%;
}


.labelContainer {
    font-size: 16px;
}



.adjustImage {
    margin-right: 40px;
    margin-top: 20px;
    zoom: 1.5;
    padding-top: 10px;
    
    
}
.labelWidthPlan, .labelWidth{
    font-size: 20px;
}

.customize {
    margin-left: -50px;
}

input[type="number"], input[type="text"], input[type="date"], input[type="url"],  input[type="submit"], .dataResult{
    font-size: 20px;
    
}


input[type="text"], input[type="date"], input[type="url"] {
    width: 250px
        

}

input[type="date"] {
    width: 150px
}



.resultsContainer {
    
    padding-top: 10px;
    padding-bottom: 10px;
}



.iconButtons {
    zoom: 1.5;
    margin-top: -40px;
    margin-bottom: -5px;
    
   }

.moreButton {
    font-size: 20px;
}
 

.forward {
   
    margin-left: 400px  
   
}


.resultBox {
    width: 25%;
   
}

div.labelWidthDates {
    width: 20%;
    display: inline-block;
    text-align: center;


}


.greetings {
    font-size: 20px;
}

.note {
    float: left;
    margin: 10px;
    padding-left: 10px;
    font-size: 12px;
    text-align: left;
    width: 100px;
    
    
    
}



.excel {
    position: absolute;
    top: 205px;
    left: 10px}
    

}


@media only screen and (min-width: 1366px) {


.excel {
    
    left: 200px}
    

.print {
    
    padding-top: 50px;
    }

.myPlans {
    padding-top: 10px;
}

input.dataResult {
    width: 35%
}


input[type= "text"].servingUnit {
    width: 50%
}


div.labelWidthPlan {
    width: 25%;
    display: inline-block;
    text-align: center;

}

.newI {

    display: flex;
    flex-wrap: wrap;
}

.newItems {
    width: 35%;
    display: inline-block;
    text-align: center;
}




div.labelWidthDates {
    width: 20%;
    display: inline-block;
    text-align: center;

}


button {
    font-size: 33px;
    height: 60px;
    
}


.labelWidthPlan, .labelWidth, .dataResult{
    font-size: 22px;
}



.labelWidth {
    padding-top: 30px;
}


.adjustImage {
    margin-top: 50px;
    zoom: 1.4;
    
    
}

input[type="number"], input[type="text"], input[type="date"], input[type="url"], input[type="submit"].dataResult{
    font-size: 21px;
    
}





.dataResult {
    margin: 10px;;
   
}

input[type="text"], input[type="date"], input[type="url"] {
    width: 300px
        

}


 
input[type="date"] {
    width: 200px
        

}

form {
    padding-bottom: 20px;
}




.arrowButtons {
    padding-top: 53px;
    zoom: 1.3
    
}



.forward {
   
    margin-left: 500px  
   
}




.charts {
    padding-top: 60px;
    border-radius: 5px;
    
    
}



.graphText {
    padding-right: 10px;
    font-size: 20px;
}


.labelContainer {
  
    width: 400%;
    display: inline-block
}

.filterContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    


}


.note {
    padding-left: 10px;
    font-size: 15px;
    width: 200px;
}

    
}
        
        
@media only screen and (min-width: 2500px) {
   
.labelWidthPlan, .labelWidth, .labelWidthDates{
        font-size: 35px;
    }
    
    

ul, li, label {
    font-size: 35px;
}
          

.customize {
    margin-left: -50px;
}

input[type="number"], input[type="text"], input[type="date"], input[type="url"], .dataResult{
    font-size: 30px;
    
}


 
input[type="text"], input[type="date"], input[type="url"] {
    width: 450px
        

}


 
input[type="date"] {
    width: 350px
        

}


.dataResult {
    width: 30%
}

input[type="number"] {
    width: 30%;
    
}

input[type="submit"] {
    width: 40%;
    
}



.iconButtons {
    width: 6%;
    zoom: 2;
    
    
}

.moreButton {
    font-size: 35px;
}



.arrowButtons {
    zoom: 1.4;
}


.forward {
   
    margin-left: 900px  
   
}



.dd  {
    padding-right: 10px;
    font-size: 35px;
}


.adjustImage {
    margin-top: 15px;
    margin-right: 30px;
    
    
}


.tooltip .tooltiptext {

    font-size: 12px;

}

label.labelContainer {
  
    font-size: 30px;
}


.scroll {
    height: 300px;
   
}


button {
    font-size: 45px;
    height: 85px;
    
}

.resultBox {
    width: 20%;
   
}




.note {
    padding-left: 10px;
    font-size: 25px;
    width: 300px;
}

.excel {
  
    top: 340px;
    left: 170px;}


.print {
      
    top: 100px;
    left: 0}
 

     
    


}


