/* Color Theme Swatches in RGBA - represents color and font palette for this app*/
:root{

  --amplify-primary-color: rgba(83, 119, 166);
  --amplify-primary-tint:  rgba(38, 11, 9, 1); 
  --amplify-primary-shade: rgba(191, 4, 4);
  --amplify-font-family: 'Poppins', sans-serif;

  }


.plum { 
    color: rgba(166, 88, 94, 1);
  }
  
.plumBackground { 
  background-color: rgba(166, 88, 94, 1);
  fill: rgba(166, 88, 94, 1);
  

}
.mauve { 
  color: rgba(140, 108, 126, 1);
}

.mauveBackground { 
  background-color: rgba(140, 108, 126, 1);
  fill: rgba(140, 108, 126, 1);
}

.red { 
  color: rgba(191, 4, 4)
}

.redBackground { 
  background-color: rgba(191, 4, 4);
  fill: rgba(191, 4, 4)
}

.black { 
  color: rgba(38, 11, 9, 1); 
}

.dark { 
  color:black
}


.lighter {
  color: rgb(92, 59, 57)
}

.blackBackground { 
  background-color: rgba(38, 11, 9, 1);
  fill: rgba(38, 11, 9, 1); 
 
}

.salmon { 
  color: rgba(242, 76, 61, 1)
 
}

.salmonBackground { 
  background-color: rgba(242, 76, 61, 1);
  fill:rgba(242, 76, 61, 1)
}

.white {
  color: white;
}

.whiteBackground {
  background-color: white;
  fill: white
}

.skin {
  color: rgba(242, 230, 223);
}

.skinBackground {
  background-color: rgba(242, 230, 223);
  fill: rgba(242, 230, 223);
}


.blue {
  color: rgba(3, 52, 115);
}

.blueBackground {
  background-color: rgba(3, 52, 115);
  fill: rgba(3, 52, 115);
}

.purple {
  color: rgba(49, 31, 115); 
} 

.purpleBackground {
  background-color: rgba(49, 31, 115);
  fill: rgba(49, 31, 115);
} 

.green {
  color: rgba(99, 140, 69)
}

.greenBackground {
  background-color: rgba(99, 140, 69);
  fill: rgba(99, 140, 69);
}

.pinkBackground {
  background-color:rgba(242, 133, 173, 1);
  fill:rgba(242, 133, 173, 1);
}

.lightBlueBackground {
  background-color:rgba(83, 119, 166)
}

.lightBlue {
  color:rgba(83, 119, 166)
}

.cloudBlueBackground {
  background-color:rgba(173, 192, 217)

}


.cloudBlue{
  color:rgba(173, 192, 217)

}
   

@import url('https://fonts.googleapis.com/css?family=Halant%7CPoppins%7CMontserrat%7CQuicksand&display=swap');

@font-face {
  font-family: 'Montebello-Sans';
  src: url('FontFiles/Montebello-Sans.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('https://fonts.googleapis.com/css?family=Halant%7CPoppins%7CMontserrat%7CQuicksand&display=swap')
}

.montebello {
  font-family: 'Montebello-Sans',truetype;
  font-weight: lighter
}

.primaryFont {
    font-family: 'Poppins', sans-serif;
    src: url('https://fonts.googleapis.com/css?family=Halant%7CPoppins%7CMontserrat%7CQuicksand&display=swap')
}

/* End of color-font*/

/*body {
  font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1.3em + (1.5 - 1.2) * ((100vw - 300px)/(1600 - 300)));
}*/

body {
  font-size: 14px;
  color: rgba(38, 11, 9, 1)4;
    line-height: 1.5;
  font-family: 'Poppins', sans-serif;
}

h1 {
  font-size: 22px;
  text-align: center;
}

h2 {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}


h3 {
  font-size: 15px;
  /*color: rgba(242, 76, 61, 1);*/
  color: rgba(191, 4, 4);
  text-align: center;
  font-family:  'Montebello-Sans', truetype;
  font-weight: lighter;
  z-index: 200;
}


h4 {
  font-size: 14px;
  text-align: center;
  font-family: 'Montebello-Sans', truetype;
  font-weight: lighter;
  line-height: 1.7;
}

p {
  color: rgba(38, 11, 9, 1); 
  border: 1px;
  line-height: 1.8;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  z-index: 1;

}
  

.noDeco{
  text-decoration: none;
}


.hidden {
  display: none;
}

ul {
  padding: 0;
}



.bold {
  font-weight: bold;
}

span {
  border-radius: 3px;
}

::placeholder, .opaque {
opacity: 0.5
}


@media only screen and (min-width: 1px) and (max-width:320px) {

  

  .fas {
    font-size: 10px;
    display: inline-block;
    width: 10%;
    text-align: center;

    margin-right: 5px;
   
    
  }
  
  
  }

@media only screen and (min-width: 1px) and (max-width:666px) {

  

.fas {
  font-size: 18px;

 

  
}


}

@media only screen and (min-width: 768px) {

  h2 {
    font-size: 25px;
    padding-top: 28px
    
    
  }


  .fas {
    font-size: 15px;
   
  }


  h3 {
    font-size: 15px;
    
  }
    

  ul, li, label {
    font-size: 20px;
  }





    
  }




@media only screen and (min-width: 1000px) {

  h1 {
    font-size: 40px;
    margin-top: -20px;
  }




  h3 {
    font-size: 20px;
    
  }
    

  ul, li, label {
    font-size: 25px;
  }
   

  label {
    font-size: 25px;
  }

  }




@media only screen and (min-width: 1367px) {

  

  h2 {
    font-size: 30px;
    padding-top: 30px
    
  }




  ul, li, label {
    font-size: 30px;
  }



.fas {
  font-size: 40px;

  

  
}

    
  }



@media only screen and (min-width: 2500px) {

  

  h2 {
    font-size: 35px;
    padding-top: 80px;
    
  }



  h3 {
    font-size: 25px;
    
  }
    

  ul, li, label {
    font-size: 32px;
  }
    
  }